import { createStore } from "vuex";
import auth from "./auth.module";
import form from "./form.module";

export default createStore({
  state() {
    return {
      // state properties
    };
  },
  mutations: {
    // mutations
  },
  actions: {
    // actions
  },
  modules: {
    auth,
    form,
  },
});
