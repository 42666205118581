import ApiService from "./api.service";
import JwtService from "./jwt.service";
import config from "@/ConfigProvider";
import router from "@/router";

export const POST_FILE = "postImage";
export const POST_GENERATE = "postGenerate";
export const POST_DESCRIBE = "postDescribe";
export const PUT_PROFILE = "putProfile";
export const POST_EDUCATION = "postEducation";
export const PUT_EDUCATION = "putEducation";
export const POST_EXPERIENCE = "postExperience";
export const PUT_EXPERIENCE = "putExperience";
export const POST_CERTIFICATE = "postCertificate";
export const PUT_CERTIFICATE = "putCertificate";
export const POST_SKILL = "postSkill";
export const PUT_SKILL = "putSkill";
export const POST_LANGUAGE = "postLanguage";
export const PUT_LANGUAGE = "putLanguage";
export const GET_PROFILE = "getProfile";
export const SET_PROFILE = "setProfile";

const state = {
  profile: [],
};

const getters = {
  generate(state) {
    return state.generate;
  },
  profile(state) {
    return state.profile;
  },
};

const actions = {
  [POST_FILE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("/playground-test/upload-file", params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_GENERATE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.postForm("/generate", params)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_DESCRIBE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.postForm("/describe",  params)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PUT_PROFILE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.putJob("/employee/me", params)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_EDUCATION](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.postJob("/education", params)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PUT_EDUCATION](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.putJob(`/education/${params.id}`, params)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_EXPERIENCE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.postJob("/experience", params)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PUT_EXPERIENCE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.putJob(`/experience/${params.id}`, params)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_CERTIFICATE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.postJob("/certificate", params)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PUT_CERTIFICATE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.putJob(`/certificate/${params.id}`, params)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_SKILL](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.postJob("/employee-skill", params)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PUT_SKILL](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.putJob(`/employee-skill/${params.id}`, params)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [POST_LANGUAGE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.postJob("/employee-language", params)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PUT_LANGUAGE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.putJob(`/employee-language/${params.id}`, params)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_PROFILE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.getJob(`/employee/${params}`)
        .then(async ({ data }) => {
          context.commit(SET_PROFILE, data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_PROFILE](state, data) {
    state.profile = data.data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
